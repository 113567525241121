import axios, { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { z, ZodSchema } from 'zod';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/consts/queryKeys.ts';
import { ISearch } from '@/modules/searches/types/ISearch.ts';
import { IEnumTradeType, IEnumTradeTypeKeys } from '@/types/enums/IEnumTradeType.ts';

export type ISearchCreateRequest = {
  categoryId: string;
  photos: string[];
  search: string;
  tradeTypes: IEnumTradeTypeKeys[];
};

export const requestCreateSchema: ZodSchema<ISearchCreateRequest> = z.object({
  categoryId: z.string().min(1, { message: t('forms.required') }),
  photos: z.array(z.string()),
  search: z.string().min(1, { message: t('forms.required') }),
  tradeTypes: z
    .array(z.enum(Object.keys(IEnumTradeType) as [IEnumTradeTypeKeys, ...[IEnumTradeTypeKeys]]))
    .nonempty(),
});

export function useSearchCreate() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<ISearch>, AxiosError, ISearchCreateRequest>({
    mutationFn: (payload) => axios.post('/search-service/search/', payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.searches] });
    },
  });
}
